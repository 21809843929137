<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12 pt-0 pb-2">
          <h1>
            Progress job #{{ job.jobNumber }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-10 col-sm-6 col-md-3">
          <v-select
            v-model="job.dom_vat_0"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Riggots supply to be within the CIS scheme?"
          ></v-select>
        </v-col>
        <v-col class="col-10 col-sm-6 col-md-3">
          <v-select
            v-model="job.dom_vat_1"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Is the customer VAT registered?"
          ></v-select>
        </v-col>
        <v-col class="col-10 col-sm-6 col-md-3">
          <v-select
            v-model="job.dom_vat_2"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Is the customer a contractor in the chain?"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-10 col-sm-6 col-md-3">
          <v-menu
            v-model="showDatePicker"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="invoiceDate"
                label="Invoice Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="date = parseDate(invoiceDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menuTwo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="col-10 col-sm-6 col-md-3">
          <v-text-field
            v-model="job.purchase_order"
            label="PO Number"></v-text-field>
        </v-col>
        <v-col class="col-10 col-sm-6 col-md-6">
          <v-text-field
            v-model="invoiceAccountNotes"
            label="Any notes for the accounts team"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3" v-if="job.purchase_order_uri">
          <v-btn
            block
            target="_blank"
            :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${job.purchase_order_uri}`">
            View Purchase Order
          </v-btn>
        </v-col>
        <v-col class="col-3" v-if="job.purchase_order_uri">
          <v-btn block @click="uploadPo = true">Replace Purchase Order</v-btn>
        </v-col>
        <v-col class="col-3" v-if="!job.purchase_order_uri">
          <v-btn block @click="uploadPo = true">Add Purchase Order</v-btn>
        </v-col>
        <v-col class="col-3">
          <router-link :to="`/quotations/view/${job.id}`">
            <v-btn block>View Quotation</v-btn>
          </router-link>
        </v-col>
        <v-col class="col-3">
          <v-btn
            block
            @click="createInvoice">Generate Proforma Invoice</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-dialog max-width="700" v-model="uploadPo">
            <v-card>
              <app-upload-po
                :quotationId="job.id"
                @closeUploader="completeUpload"></app-upload-po>
              <v-card-actions>
                <v-btn text @click="uploadPo = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col>
          <h4>Job details:</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <p>
            Customer:<br />
            {{ job.customer }}<br />
            <span v-if="job.customerAddressOne">{{ job.customerAddressOne }}<br /></span>
            <span v-if="job.customerAddressTwo">{{ job.customerAddressTwo }}<br /></span>
            <span v-if="job.customerAddressThree">{{ job.customerAddressThree }}<br /></span>
            <span v-if="job.customerAddressFour">{{ job.customerAddressFour }}<br /></span>
            <span v-if="job.customerAddressFive">{{ job.customerAddressFive }}<br /></span>
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <p>
            Site address:<br />
            {{ job.site }}<br />
            {{ job.postCode }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <p>
            Work description:<br />
            {{ job.description }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>Minimum charges:</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(charge, index) in job.minimumCharges" :key="index">
                <td>{{ charge.description }}</td>
                <td>{{ charge.charge | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>BOQs (From quotation):</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Material</th>
                <th>Colour</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(line, index) in job.quotationLines"
                :key="index">
                <td>{{ line.boq }}</td>
                <td>{{ line.material }}</td>
                <td>{{ line.colour }}</td>
                <td>{{ line.unit }}</td>
                <td>{{ line.quantity }}</td>
                <td>
                  @{{ line.price | priceInPounds | currency }} =
                  {{ (line.quantity * line.price) | priceInPounds | currency }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-for="(drs, index) in job.drs" :key="index">
            <v-btn
              x-small
              :href="`https://app.yoursafetypal.com/daily_return_sheets/view/${drs.pal_id}`"
              target="blank">
              View DRS #{{ index + 1 }}
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <p>BOQs (From DRS):</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>BOQ</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(drsBoq, index) in job.drsBoqs" :key="index">
                <td>{{ drsBoq.name }}</td>
                <td>{{ drsBoq.quantity }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="pt-5" v-if="job.documents">
        <v-col class="col-12">
          <h4>Documents attached to this job</h4>
        </v-col>
        <v-col
          class="col-12"
          v-for="(document, index) in job.documents"
          :key="index">
          <v-row>
            <v-col class="col-8">
              <a
                :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${document}`"
                target="_blank">
                View: {{ document.substring(document.indexOf("__") + 2) }}
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import UploadPo from '@/components/Quotations/UploadPO.vue';
  import axios from '../../axios';
  
  export default {
    name: 'Progress',
    components: {
      appUploadPo: UploadPo,
    },
    data() {
      return {
        job: {
          id: 0,
          documents: [],
        },
        selectedJob: 0,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        invoiceDate: '',
        invoiceAccountNotes: '',
        showDatePicker: false,
        uploadPo: false,
      };
    },
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    watch: {
      date () {
        this.invoiceDate = this.formatDate(this.date);
      },
    },
    methods: {
      completeUpload(uri) {
        this.quotation.purchase_order_uri = uri;
      },
      formatDate (date) {
        if (!date) return null;
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      getJob() {
        axios.get(`/proformas/getSingleInfo/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.job = response.data;
        });
      },
      createInvoice() {
        const postData = {};
        postData.date = this.invoiceDate;
        postData.notes = this.invoiceAccountNotes;
        postData.job = this.job;
        postData.userId = this.$store.state.userid;
        axios.post(`/proformas/create.json?token=${this.token}`, postData)
          .then((response) => {
            this.$router.push(`/proformas/view-draft/${response.data.invoiceId}`);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getJob();
      this.invoiceDate = this.formatDate(this.date);
    },
  };
  </script>
  